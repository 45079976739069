
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class SmartPlugScheduleSavePopup extends Vue {
  @Prop({ default: false }) readonly hasDiscardButton!: boolean;
  @Prop() readonly isProcessing!: boolean;

  onClickCancel() {
    this.$emit('onClickCancel');
  }

  onClickSave() {
    this.$emit('onClickSave');
  }

  onClickDiscard() {
    this.$emit('onClickDiscard');
  }

  onClickDelete() {}
}
