
// @ts-nocheck

import { Component, Vue, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { cloneDeep, filter, map, flatten, forEach, find, sortBy } from 'lodash';
import omit from 'lodash/omit';
import moment from 'moment-timezone';
import naturalSort from 'javascript-natural-sort';
import { ToggleButton } from 'vue-js-toggle-button';

import SectionHeading from '@/components/heading/SectionHeading.vue';
import SectionH1 from '@/components/heading/SectionH1.vue';
import SidePanel from '@/components/nav/SidePanel.vue';
import CloseButton from '@/components/CloseButton.vue';

import { getLastUpdateTimeFromDateString } from '@/utils/time';

import { DeviceUserConfig } from '@/types/index.d';

import SearchTextField from '@/components/form/SearchTextField.vue';
import VBDropdownSearch from '@/components/custom-views/vb-dropdown-search/classes/vb-dropdown-search.vue';

import SmartPlugAddPlugPopup from '@/components/smart-plug/popup/SmartPlugAddPlugPopup.vue';
import SmartPlugEditNamePopup from '@/components/smart-plug/popup/SmartPlugEditNamePopup.vue';
import SmartPlugLinkDevicePopup from '@/components/smart-plug/popup/SmartPlugLinkDevicePopup.vue';
import SmartPlugGlobalSchedulePopup from '@/components/smart-plug/popup/SmartPlugGlobalSchedulePopup.vue';
import SmartPlugSettingsPopup from '@/components/smart-plug/popup/SmartPlugSettingsPopup.vue';
import SmartPlugLineBotOTPPopup from '@/components/smart-plug/popup/SmartPlugLineBotOTPPopup.vue';
import SmartPlugLineBotConfigPopup from '@/components/smart-plug/popup/SmartPlugLineBotConfigPopup.vue';

import SmartPlugScheduleTimeline from '@/components/smart-plug/schedule/SmartPlugScheduleTimeline.vue';
import SmartPlugScheduleViewPopup from '@/components/smart-plug/popup/SmartPlugScheduleViewPopup.vue';
import DeletePopup from '@/components/popup/DeletePopup.vue';

import {
  getSmartPlugPlatforms,
  getSmartPlugPlatformGroups,
  getSmartPlugDevices,
  getSmartPlugSubGroups,
  removeSmartPlugDevice,
  unforceAllSmartPlugDevices,
  forceAllSmartPlugDevices,
  unforceSmartPlugDevice,
  forceSmartPlugDevice,
  linkSmartPlugWithAndroidBoxIds,
  linkSmartPlugWithSmartPlugs,
  updateSmartPlugDevice,
} from '@/services/axios';

const linkedDevicesCollapseLimit = 5;

const COMMAND_TURN_ON_ALL = 'COMMAND_TURN_ON_ALL';
const COMMAND_TURN_OFF_ALL = 'COMMAND_TURN_OFF_ALL';
const COMMAND_DELETE = 'COMMAND_DELETE';

@Component({
  components: {
    ToggleButton,
    SectionHeading,
    SectionH1,
    SidePanel,
    CloseButton,
    SmartPlugAddPlugPopup,
    SmartPlugEditNamePopup,
    SmartPlugLinkDevicePopup,
    SmartPlugGlobalSchedulePopup,
    SmartPlugSettingsPopup,
    SmartPlugLineBotOTPPopup,
    SmartPlugLineBotConfigPopup,
    SmartPlugScheduleTimeline,
    SearchTextField,
    SmartPlugScheduleViewPopup,
    DeletePopup,
    VBDropdownSearch,
  },
})
export default class SmartPlugView extends Vue {
  @State opId!: number;
  @State devices!: DeviceUserConfig[];

  searchText: string = '';

  selectedPlug: any = null;
  selectedPlugActionIndex: number = -1;
  selectedPlugSchedule: any = null;
  selectedFilterItem: any = null;

  isShowingAllLinkedDevices: boolean = false;

  isManageMenuVisible: boolean = false;
  isPlugManageMenuVisible: boolean = false;
  isSelectedPlugCommandVisible: boolean = false;
  isFilterItemVisible: boolean = false;

  isAddPlugPopupVisible: boolean = false;
  isEditPlugNamePopupVisible: boolean = false;
  isPlugLinkSignPopupVisible: boolean = false;
  isGlobalSchedulePopupVisible: boolean = false;
  isLineBotSettingsPopupVisible: boolean = false;
  isLineBotOTPPopupVisible: boolean = false;

  isDeletePopupVisible: boolean = false;

  isShowAllLinkedSign: boolean = false;

  isNameChangedProcessing: boolean = false;
  isPopupProcessing: boolean = false;

  isLoadingPlugs: boolean = false;
  isScheduleViewPopupVisible: boolean = false;

  locale: any = null;
  platforms: any[] = [];
  selectedPlatform: any = null;
  groups: any[] = [];
  plugs: any[] = [];
  globalSchedules: any[] = [];
  viewingSchedule: any = null;
  autoSelectedSchedule: any = null;

  deleteAction: any = null;
  deleteHtmlTitle: string = '';
  deleteButtonTitle: string = '';
  isDeleteDanger: boolean = false;

  refreshInterval: any = null;
  isPreventRefreshInterval: boolean = false;
  refreshDate: string = '-';

  @Watch('selectedPlatform')
  onPlatformChanged() {
    this.loadPlugs();
    this.loadGlobalSchedules();
  }

  get platformId() {
    if (this.selectedPlatform) {
      return this.selectedPlatform.plt_id;
    }
    if (this.platforms && this.platforms.length) {
      return this.platforms[0].plt_id;
    }
    return null;
  }

  get groupId() {
    if (this.groups && this.groups.length) {
      return this.groups[0].grp_id;
    }
    return null;
  }

  get filterItemTitle() {
    if (this.selectedFilterItem) {
      return this.selectedFilterItem.name;
    }
    return 'กรองข้อมูล';
  }

  get manageMenuItems() {
    return [
      {
        id: 'schedule',
        name: 'จัดการเวลา',
        icon: 'icon-clock',
        onClick: () => {
          this.closeManageMenus();
          this.isGlobalSchedulePopupVisible = true;
        },
      },
      {
        id: COMMAND_TURN_ON_ALL,
        name: 'บังคับเปิดทั้งหมด',
        icon: 'icon-light-on',
        onClick: () => {
          // forEach(this.plugs, plug => {
          //   plug.force_close = false;
          //   plug.force_open = true;
          // });
          // localStorage.setItem('plugs', JSON.stringify(this.plugs));
          // this.testLoadPlugs();
          this.closeManageMenus();
          this.deleteAction = COMMAND_TURN_ON_ALL;
          this.deleteHtmlTitle = 'คุณแน่ใจต้องการเปิดปลั๊กทั้งหมดใช่ไหม ?';
          this.deleteButtonTitle = 'ใช่, เปิดเลย';
          this.isDeleteDanger = false;
          this.isDeletePopupVisible = true;
        },
      },
      {
        id: COMMAND_TURN_OFF_ALL,
        name: 'บังคับปิดทั้งหมด',
        icon: 'icon-light-off',
        onClick: () => {
          // forEach(this.plugs, plug => {
          //   plug.force_close = true;
          //   plug.force_open = false;
          // });
          // localStorage.setItem('plugs', JSON.stringify(this.plugs));
          // this.testLoadPlugs();
          this.closeManageMenus();
          this.deleteAction = COMMAND_TURN_OFF_ALL;
          this.deleteHtmlTitle = 'คุณแน่ใจต้องการปิดปลั๊กทั้งหมดใช่ไหม ?';
          this.deleteButtonTitle = 'ใช่, ปิดเลย';
          this.isDeleteDanger = true;
          this.isDeletePopupVisible = true;
        },
      },
      {
        id: 'revoke-plugs-command',
        name: 'ยกเลิกคำสั่ง',
        icon: 'icon-cross',
        onClick: () => {
          // forEach(this.plugs, plug => {
          //   plug.force_close = false;
          //   plug.force_open = false;
          // });
          // localStorage.setItem('plugs', JSON.stringify(this.plugs));
          // this.testLoadPlugs();
          this.closeManageMenus();
          this.isLoadingPlugs = true;
          unforceAllSmartPlugDevices(this.opId, this.platformId, this.groupId)
            .then(() => {
              this.showToastSuccess('ยกเลิกคำสั่งบังคับเปิด/ปิดปลั๊กทั้งหมดแล้ว');
              this.loadPlugs();
            })
            .catch(err => {
              this.isLoadingPlugs = false;
              this.showAllPlugsCommandToastError(err);
            })
            .finally();
        },
      },
      // {
      //   id: 'activate-line-bot',
      //   name: 'เปิดใช้งาน BOT',
      //   icon: 'icon-activate-line-bot',
      //   hasTopBorder: true,
      //   onClick: () => {
      //     this.closeManageMenus();
      //     this.isLineBotOTPPopupVisible = true;
      //   },
      // },
      // {
      //   id: 'set-line-bot',
      //   name: 'ตั้งค่า LINE BOT',
      //   icon: 'icon-line-bot',
      //   onClick: () => {
      //     this.closeManageMenus();
      //     this.isLineBotSettingsPopupVisible = true;
      //   },
      // },
    ];
  }

  get plugMenuItems() {
    return [
      {
        id: 'change-plug-name',
        name: 'แก้ไขชื่อ',
        icon: 'icon-pen',
        onClick: () => {
          this.isEditPlugNamePopupVisible = true;
          this.closePlugMenus();
        },
      },
      {
        id: 'delete-plug',
        name: 'ลบเครื่อง',
        icon: 'icon-red-bin',
        color: '#ff0033',
        hasTopBorder: true,
        onClick: () => {
          this.deleteAction = COMMAND_DELETE;
          this.deleteHtmlTitle = `<div>คุณแน่ใจต้องการลบ <span style="color: #99aaff;">“${this.selectedPlug.dflt_name}”</span> ใช่ไหม</div>`;
          this.deleteButtonTitle = 'ลบ';
          this.isDeleteDanger = true;
          this.isDeletePopupVisible = true;
          this.closePlugMenus();
        },
      },
    ];
  }

  get filterItems() {
    return [
      {
        id: 'running',
        name: 'กำลังจ่ายไฟ',
        icon: 'icon-light-on',
        onClick: () => {},
      },
      {
        id: 'stopped',
        name: 'หยุดจ่ายไฟ',
        icon: 'icon-light-off',
        onClick: () => {},
      },
      {
        id: 'clear-filter',
        name: 'ล้างตัวกรอง',
        icon: 'icon-cross',
        onClick: () => {},
      },
    ];
  }

  get shouldShowExpandLinkedDevicesButton() {
    return this.selectedPlug.linked_devices.length > linkedDevicesCollapseLimit;
  }

  get displayedLinkedDevices() {
    const ports = this.selectedPlug.ports;
    if (ports.length) {
      const port = ports[0];
      let androidBoxes = port.signs;
      let plugs = port.peripheral;

      let devices: any[] = [];
      if (androidBoxes) {
        forEach(androidBoxes, box => {
          const foundBox = find(this.devices, { usr_id: box.usr_id });
          const boxName = foundBox ? foundBox.name : '';
          devices.push({
            usr_id: box.usr_id,
            id: box.usr_id,
            type: 'android_box',
            name: boxName,
            is_online: this.isDeviceOnline(foundBox),
          });
        });
      }
      if (plugs) {
        forEach(plugs, plug => {
          const foundPlug = find(this.plugs, { dvc_id: plug.prp_dvc_id });
          const plugName = foundPlug ? foundPlug.dflt_name : '';
          devices.push({
            ...foundPlug,
            id: foundPlug.id,
            type: 'plug',
            name: plugName,
            is_online: foundPlug.is_online,
            is_turn_on: foundPlug.prt_state,
          });
        });
      }
      if (devices.length) {
        return devices.sort((a, b) => naturalSort(a.name, b.name));
      }
    }

    return [];
  }

  get availableToLinkPlugs() {
    if (this.selectedPlug) {
      return filter(this.plugs, plug => {
        return plug.id !== this.selectedPlug.id;
      });
    }
    return [];
  }

  get availableToLinkAndroidBoxes() {
    if (this.devices) {
      return map(
        this.devices.sort((a, b) => naturalSort(a.name, b.name)),
        device => {
          return { ...device, id: device.usr_id };
        }
      );
    }
    return [];
  }

  get selectedPlugScheduleTimeline() {
    if (this.selectedPlug) {
      const schedule = this.getGlobalScheduleOfPlug(this.selectedPlug);
      if (schedule && schedule.days) {
        return schedule.days;
      }
    }
    return [];
  }

  get plugForceCommandItems() {
    return [
      { id: 0, name: 'บังคับเปิด' },
      { id: 1, name: 'บังคับปิด' },
    ];
  }

  get contentRightMargin() {
    return this.selectedPlug ? '340px' : '0px';
  }

  get isPopupVisible(): boolean {
    return this.shouldDimView;
  }

  get shouldDimView(): boolean {
    return (
      this.isAddPlugPopupVisible ||
      this.isEditPlugNamePopupVisible ||
      this.isPlugLinkSignPopupVisible ||
      this.isGlobalSchedulePopupVisible ||
      this.isLineBotSettingsPopupVisible ||
      this.isLineBotOTPPopupVisible ||
      this.isScheduleViewPopupVisible ||
      this.isDeletePopupVisible
    );
  }

  get shortWeekdays(): string[] {
    return ['จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส', 'อา'];
  }

  get status() {
    return this.$route.query.status || 'all';
  }

  get onlinePlugs() {
    return filter(this.plugs, plug => {
      return plug.is_online;
    });
  }
  get offlinePlugs() {
    return filter(this.plugs, plug => {
      return !plug.is_online;
    });
  }

  get filteredPlugs() {
    let plugs = this.plugs;

    if (this.status === 'online') {
      plugs = this.onlinePlugs;
    } else if (this.status === 'offline') {
      plugs = this.offlinePlugs;
    }

    if (this.selectedFilterItem) {
      plugs = filter(plugs, plug => {
        return this.selectedFilterItem.id === 'running' ? plug.prt_state : !plug.prt_state;
      });
    }

    plugs = filter(plugs, (plug: any) => {
      return plug.dflt_name && plug.dflt_name.toLowerCase().includes(this.searchText.toLowerCase());
    });

    plugs = plugs.sort((a, b) => naturalSort(a.dflt_name, b.dflt_name));

    return plugs;
  }
  get totalPlug() {
    return this.plugs.length || 0;
  }
  get totalOnlinePlug() {
    return this.onlinePlugs.length || 0;
  }
  get totalOfflinePlug() {
    return this.offlinePlugs.length || 0;
  }

  plugList: any[] = flatten([
    this.getTestPlugList(),
    this.getTestPlugList(),
    this.getTestPlugList(),
    this.getTestPlugList(),
  ]);

  onSelectedPlatform(platform: any) {
    this.selectedPlatform = platform;
  }

  isDeviceOnline(device: DeviceUserConfig | undefined): boolean {
    if (device && device.online_at) {
      let diff = moment().diff(moment(`${device.online_at}Z`), 'minutes');
      if (diff <= 15) {
        return true;
      }
    }
    return false;
  }

  getLastUpdateTimeOfPlug(plug: any) {
    return getLastUpdateTimeFromDateString(
      plug.is_online ? plug.mod_at : plug.last_update_at,
      !plug.is_online,
      true
    );
  }

  getGlobalScheduleNameOfPlug(plug: any) {
    const schedule = this.getGlobalScheduleOfPlug(plug);
    if (schedule) {
      return schedule.name;
    }
    return 'ไม่มีตารางเวลา';
  }

  getGlobalScheduleStatusOfPlug(plug: any) {
    const schedule = this.getGlobalScheduleOfPlug(plug);
    if (schedule) {
      return schedule.is_act ? 'เปิดใช้งาน' : 'ปิดใช้งาน';
    }
    return null;
  }

  getGlobalScheduleOfPlug(plug: any) {
    const globalScheduleId = plug.global_schedule_id;
    if (globalScheduleId) {
      const schedule = find(this.globalSchedules, {
        sch_id: globalScheduleId,
        sgp_id: plug.sgp_id,
      });
      if (schedule) {
        return schedule;
      }
    }
    return null;
  }

  getScheduleMarginLeftOfScheduleTime(time: any) {
    return '0';
    // return `${(time.startMins / 1440) * 100}%`;
  }

  getScheduleWidthOfScheduleTime(time: any) {
    return '100%';
    // return `${((time.endMins - time.startMins) / 1440) * 100}%`;
  }

  getPlugLinkedDeviceNames(plug: any) {
    const ports = plug.ports;
    if (ports.length) {
      const port = ports[0];
      let androidBoxes = port.signs;
      let plugs = port.peripheral;

      let deviceNames: string[] = [];
      if (androidBoxes) {
        const androidBoxUsrIds = map(androidBoxes, box => {
          return box.usr_id;
        });
        forEach(androidBoxUsrIds, usrId => {
          const box = find(this.devices, { usr_id: usrId });
          if (box) {
            deviceNames.push(box.name);
          }
        });
      }
      if (plugs) {
        const plugDvcIds = map(plugs, plug => plug.prp_dvc_id);
        forEach(plugDvcIds, dvcId => {
          const plug = find(this.plugs, { dvc_id: dvcId });
          if (plug) {
            deviceNames.push(plug.dflt_name);
          }
        });
      }
      if (deviceNames.length) {
        return deviceNames.join(', ');
      }
    }
    return 'ไม่ได้ผูกอุปกรณ์';
  }

  getPlugForceCommandItems(plug: any) {
    let commands = [];
    if (plug.force_open) {
      commands.push({ id: 1, name: 'บังคับปิด', description: 'ปิดนอกตารางเวลา' });
      commands.push({ id: 2, name: 'ไม่บังคับ', description: 'เปิด/ปิด ตามตารางเวลา' });
    } else if (plug.force_close) {
      commands.push({ id: 0, name: 'บังคับเปิด', description: 'เปิดนอกตารางเวลา' });
      commands.push({ id: 2, name: 'ไม่บังคับ', description: 'เปิด/ปิด ตามตารางเวลา' });
    } else {
      commands.push({ id: 0, name: 'บังคับเปิด', description: 'เปิดนอกตารางเวลา' });
      commands.push({ id: 1, name: 'บังคับปิด', description: 'ปิดนอกตารางเวลา' });
    }
    return commands;
  }

  openGlobalScheduleWithAutoSelectedSchedule() {
    this.isScheduleViewPopupVisible = false;
    this.autoSelectedSchedule = this.getGlobalScheduleOfPlug(this.selectedPlug);
    this.isGlobalSchedulePopupVisible = true;
  }

  isSelectedPlug(plug: any) {
    if (this.selectedPlug) {
      return this.selectedPlug.id == plug.id;
    }
    return false;
  }

  onSearchDeviceTextChanged(text: string) {
    this.searchText = text;
  }

  refresh(keepSelectedPlug: boolean) {
    if (!keepSelectedPlug) {
      this.selectPlug(null);
    }
    this.loadData();
  }

  viewScheduleOfPlug(plug: any) {
    const schedule = this.getGlobalScheduleOfPlug(plug);
    if (schedule) {
      this.viewingSchedule = schedule;
      this.isScheduleViewPopupVisible = true;
    }
  }

  selectFilterItem(item: any) {
    if (item.id === 'clear-filter') {
      this.selectedFilterItem = null;
    } else {
      this.selectedFilterItem = item;
    }
    this.closeFilterItems();
  }

  selectDevice(device: any) {
    if (device.type === 'plug') {
      let dv = find(this.plugs, { id: device.id });
      if (dv) {
        this.selectPlug(dv);

        const plugRefs: any = this.$refs[`plug_${device.id}`];
        const scrollViewRef: any = this.$refs.scrollView;

        if (plugRefs && plugRefs.length) {
          const plugRef: any = plugRefs[0];
          if (plugRef && scrollViewRef) {
            scrollViewRef.scrollTo(0, plugRef.offsetTop - 174);
          }
        }
      }
    } else {
      const foundDevice = find(this.devices, dv => {
        return dv.usr_id === device.id;
      });
      if (foundDevice) {
        this.$router.push({
          name: 'devices-view',
          params: {
            opId: `${this.opId}`,
          },
          query: {
            device: foundDevice.pub_key,
          },
        });
      }
    }
  }

  selectPlug(plug: any) {
    this.selectedPlug = plug;

    if (!this.selectedPlug) {
      const query = omit(this.$route.query, 'plug');
      this.$router.replace({ query });
      return;
    }

    const globalScheduleId = this.selectedPlug.global_schedule_id;
    if (globalScheduleId) {
      const globalSchedule = find(this.globalSchedules, { sch_id: globalScheduleId });
      if (globalSchedule) {
        this.selectedPlugSchedule = globalSchedule;
      } else {
        this.selectedPlugSchedule = null;
      }
    }

    const plugId = this.$route.query.plug;
    if (plugId && plugId === plug.id) {
      return;
    }

    let query = { ...this.$route.query };
    if (plug) {
      query['plug'] = plug.id;
    }
    this.$router.replace({ query });
  }

  closeSidePanel() {
    this.selectedPlug = null;
    this.closePlugMenus();
  }

  getDefaultScheduleModel() {
    return {
      name: '',
      is_act: false,
      days: [
        {
          times: [],
        },
        {
          times: [],
        },
        {
          times: [],
        },
        {
          times: [],
        },
        {
          times: [],
        },
        {
          times: [],
        },
        {
          times: [],
        },
      ],
      is_selected: false,
      schedule_names: [],
      plugs: [],
    };
  }

  getMappedSchedule(schedule: any, subgroup: any = null) {
    let mappedSchedule: any = this.getDefaultScheduleModel();
    mappedSchedule = { ...mappedSchedule, ...schedule };
    const times = schedule.sch_times;

    if (times && times.length) {
      let schIndex = 0;
      forEach(times, time => {
        if (schIndex % 2 !== 0) {
          schIndex += 1;
          return;
        }

        schIndex += 1;

        let index = time[0] - 1;
        if (index < 0) {
          index = 6;
        }

        let endSecs = times[schIndex][1];
        if (endSecs === 86399) {
          endSecs = 86400;
        }

        const startMins = time[1] / 60;
        const endMins = endSecs / 60;

        const start =
          `${Math.floor(startMins / 60)}`.padStart(2, '0') +
          ':' +
          `${startMins % 60}`.padStart(2, '0');

        const end =
          `${Math.floor(endMins / 60)}`.padStart(2, '0') + ':' + `${endMins % 60}`.padStart(2, '0');

        mappedSchedule.days[index].times[0] = {
          startMins: startMins,
          endMins: endMins,
          start: start,
          end: end,
        };
      });
    }
    mappedSchedule.is_act = schedule.is_act;
    mappedSchedule.name = schedule.dflt_name;
    mappedSchedule.sch_id = schedule.sch_id;

    if (subgroup && subgroup.devices) {
      forEach(subgroup.devices, deviceId => {
        const plug = find(this.plugs, { dvc_id: deviceId });
        if (plug) {
          mappedSchedule.plugs.push(plug);
        }
      });
    }

    return mappedSchedule;
  }

  getMappedPlug(plug: any) {
    let mappedSchedules: any = {
      common: [],
      special: [],
      commonScheduleNames: '',
      specialScheduleNames: '',
    };
    const ports = plug.ports;
    if (ports && ports.length) {
      const port = ports[0];
      const schedules = port.schedules;
      if (schedules && schedules.length) {
        let commonSchedules = filter(schedules, sch => {
          return sch.act_from === null;
        });

        commonSchedules = map(commonSchedules, sch => {
          return this.getMappedSchedule(sch);
        });
        let specialSchedules = filter(schedules, sch => {
          return sch.act_from !== null;
        });
        specialSchedules = map(specialSchedules, sch => {
          return this.getMappedSchedule(sch);
        });

        mappedSchedules.common = commonSchedules;
        mappedSchedules.special = specialSchedules;

        mappedSchedules.commonScheduleNames = map(commonSchedules, schedule => {
          return schedule.name.toLowerCase();
        }).join(' ');
        mappedSchedules.specialScheduleNames = map(specialSchedules, schedule => {
          return schedule.name.toLowerCase();
        }).join(' ');
      }
    }
    return {
      ...plug,
      id: plug.plt_uuid,
      global_schedule_id:
        plug.global_schedule_ids && plug.global_schedule_ids.length
          ? plug.global_schedule_ids[0]
          : null,
      mappedSchedules: mappedSchedules,
      prt_state: plug.ports[0].prt_state,
      force_open:
        plug.force_cmd_at === null ? false : plug.ports[0].prt_state === true ? true : false,
      force_close:
        plug.force_cmd_at === null ? false : plug.ports[0].prt_state === false ? true : false,
      linked_devices: [],
    };
  }

  getTestPlugList() {
    return [
      {
        id: [...Array(30)].map(() => Math.random().toString(36)[2]).join(''),
        is_online: true,
        last_active: '',
        dflt_name: `Plug ${[...Array(8)].map(() => Math.random().toString(36)[2]).join('')}`,
        imei: [...Array(22)].map(() => Math.random().toString(36)[2]).join(''),
        is_sign_linked: true,
        is_scheduled: true,
        schedules: {
          common: [],
          special: [],
        },
        wifi: `${[...Array(8)].map(() => Math.random().toString(36)[2]).join('')}_Net`,
        linked_devices: [],
        prt_state: true,
      },
      {
        id: [...Array(30)].map(() => Math.random().toString(36)[2]).join(''),
        is_online: false,
        last_active: '',
        dflt_name: `Plug ${[...Array(8)].map(() => Math.random().toString(36)[2]).join('')}`,
        imei: [...Array(22)].map(() => Math.random().toString(36)[2]).join(''),
        is_sign_linked: true,
        is_scheduled: true,
        schedules: {
          common: [],
          special: [],
        },
        wifi: `${[...Array(8)].map(() => Math.random().toString(36)[2]).join('')}_Net`,
        linked_devices: [],
        prt_state: false,
      },
      {
        id: [...Array(30)].map(() => Math.random().toString(36)[2]).join(''),
        is_online: true,
        last_active: '',
        dflt_name: `Plug ${[...Array(8)].map(() => Math.random().toString(36)[2]).join('')}`,
        imei: [...Array(22)].map(() => Math.random().toString(36)[2]).join(''),
        is_sign_linked: false,
        is_scheduled: false,
        schedules: {
          common: [],
          special: [],
        },
        wifi: `${[...Array(8)].map(() => Math.random().toString(36)[2]).join('')}_Net`,
        linked_devices: [],
        prt_state: true,
      },
      {
        id: [...Array(30)].map(() => Math.random().toString(36)[2]).join(''),
        is_online: false,
        last_active: '',
        dflt_name: `Plug ${[...Array(8)].map(() => Math.random().toString(36)[2]).join('')}`,
        imei: [...Array(22)].map(() => Math.random().toString(36)[2]).join(''),
        is_sign_linked: false,
        is_scheduled: false,
        schedules: {
          common: [],
          special: [],
        },
        wifi: `${[...Array(8)].map(() => Math.random().toString(36)[2]).join('')}_Net`,
        linked_devices: [],
        prt_state: false,
      },
    ];
  }

  onClickConfirmDelete() {
    if (this.deleteAction) {
      if (this.deleteAction === COMMAND_TURN_OFF_ALL) {
        this.isPopupProcessing = true;
        forceAllSmartPlugDevices(this.opId, this.platformId, this.groupId, false)
          .then(() => {
            this.isDeletePopupVisible = false;
            this.isLoadingPlugs = true;
            this.showToastSuccess('บังคับปิดปลั๊กทั้งหมดแล้ว');
            this.loadPlugs();
          })
          .catch(err => {
            this.showAllPlugsCommandToastError(err);
          })
          .finally(() => {
            this.isPopupProcessing = false;
          });
      } else if (this.deleteAction === COMMAND_TURN_ON_ALL) {
        this.isPopupProcessing = true;
        forceAllSmartPlugDevices(this.opId, this.platformId, this.groupId, true)
          .then(() => {
            this.isDeletePopupVisible = false;
            this.isLoadingPlugs = true;
            this.showToastSuccess('บังคับเปิดปลั๊กทั้งหมดแล้ว');
            this.loadPlugs();
          })
          .catch(err => {
            this.showAllPlugsCommandToastError(err);
          })
          .finally(() => {
            this.isPopupProcessing = false;
          });
      } else if (this.deleteAction === COMMAND_DELETE) {
        this.isPopupProcessing = true;

        removeSmartPlugDevice(this.opId, this.platformId, this.groupId, this.selectedPlug.dvc_id)
          .then(res => {
            this.isDeletePopupVisible = false;
            this.isLoadingPlugs = true;
            this.showToastSuccess('ลบปลั๊กเรียบร้อยแล้ว');
            this.selectPlug(null);
            this.refresh(false);
          })
          .catch(err => {
            this.showToastError(null, err);
          })
          .finally(() => {
            this.isPopupProcessing = false;
          });
      }
    }
  }

  onGlobalScheduleClosed() {
    this.autoSelectedSchedule = null;
    this.isGlobalSchedulePopupVisible = false;
  }

  onPlugNameChanged(name: string) {
    this.isNameChangedProcessing = true;

    updateSmartPlugDevice(this.opId, this.platformId, this.groupId, this.selectedPlug.dvc_id, {
      dflt_name: name,
    })
      .then(() => {
        this.showToastSuccess(null);
        this.isEditPlugNamePopupVisible = false;
        this.isNameChangedProcessing = false;
        this.refresh(true);
      })
      .catch(err => {
        this.showToastError(null, err);
      })
      .finally(() => {
        this.isNameChangedProcessing = false;
      });
  }

  onLinkedDeviceSelected(linkedDevices: any[]) {
    if (this.selectedPlug) {
      this.isPopupProcessing = true;

      const plugs = filter(linkedDevices, device => {
        return device.type === 'plug';
      });
      const androidBoxes = filter(linkedDevices, device => {
        return device.type === 'android_box';
      });

      const requestPlugs = map(plugs, plug => {
        return { op_id: plug.op_id, plt_id: plug.plt_id, grp_id: plug.grp_id, dvc_id: plug.dvc_id };
      });

      const requestAndroidBoxIds = map(androidBoxes, box => {
        return box.id;
      });

      Promise.all([
        linkSmartPlugWithSmartPlugs(
          this.opId,
          this.platformId,
          this.groupId,
          this.selectedPlug.dvc_id,
          this.selectedPlug.dflt_prt_id,
          requestPlugs
        ),
        linkSmartPlugWithAndroidBoxIds(
          this.opId,
          this.platformId,
          this.groupId,
          this.selectedPlug.dvc_id,
          this.selectedPlug.dflt_prt_id,
          requestAndroidBoxIds
        ),
      ])
        .then(values => {
          this.isPlugLinkSignPopupVisible = false;
          this.loadPlugs();
        })
        .catch(err => {
          this.showToastError(null, err);
        })
        .finally(() => {
          this.isPopupProcessing = false;
        });

      // this.selectedPlug.linked_devices = linkedSign;
      // localStorage.setItem('plugs', JSON.stringify(this.plugs));
    }
  }

  onCommonSchedulesChanged(schedules: any) {
    this.loadPlugs();
    // this.selectedPlug.schedules.common = schedules;
  }

  onSpecialSchedulesChanged(schedules: any) {
    this.selectedPlug.schedules.special = schedules;
  }

  closeManageMenus() {
    if (this.isManageMenuVisible) {
      setTimeout(() => {
        this.isManageMenuVisible = false;
      }, 1);
    }
  }

  closePlugMenus() {
    if (this.isPlugManageMenuVisible) {
      this.isPlugManageMenuVisible = false;
    }
  }

  closeFilterItems() {
    if (this.isFilterItemVisible) {
      this.isFilterItemVisible = false;
    }
  }

  openPlugCommand(plugIndex: number) {
    this.closeManageMenus();
    this.closePlugMenus();
    this.closeSelectedPlugCommand();
    if (plugIndex === this.selectedPlugActionIndex) {
      this.closePlugCommand();
    } else {
      this.selectedPlugActionIndex = plugIndex;
    }
  }

  closePlugCommand() {
    this.selectedPlugActionIndex = -1;
  }

  selectPlugCommand(command: any, plugIndex: number) {
    const plug = this.filteredPlugs[plugIndex];

    this.isLoadingPlugs = true;

    if (command && command.id !== 2) {
      let prtState = command.id === 0 ? true : false;
      forceSmartPlugDevice(this.opId, this.platformId, this.groupId, plug.dvc_id, prtState)
        .then(res => {
          this.loadPlugs();
        })
        .catch(err => {
          this.showToastError('ไม่สามารถส่งคำสั่งได้ กรุณาลองอีกครั้ง', err);
        });
    } else {
      unforceSmartPlugDevice(this.opId, this.platformId, this.groupId, plug.dvc_id)
        .then(res => {
          this.loadPlugs();
        })
        .catch(err => {
          this.showToastError('ไม่สามารถยกเลิกคำสั่งได้ กรุณาลองอีกครั้ง', err);
        });
    }

    setTimeout(() => {
      this.closePlugCommand();
    }, 1);
  }

  openSelectedPlugCommand() {
    this.isSelectedPlugCommandVisible = true;
  }

  closeSelectedPlugCommand() {
    if (this.isSelectedPlugCommandVisible) {
      this.isSelectedPlugCommandVisible = false;
    }
  }

  selectPlugCommandOnSelectedPlug(command: any) {
    this.isLoadingPlugs = true;

    if (command && command.id !== 2) {
      let prtState = command.id === 0 ? true : false;

      forceSmartPlugDevice(
        this.opId,
        this.platformId,
        this.groupId,
        this.selectedPlug.dvc_id,
        prtState
      )
        .then(res => {
          this.loadPlugs();
        })
        .catch(err => {
          this.showToastError('ไม่สามารถส่งคำสั่งได้ กรุณาลองอีกครั้ง', err);
        });
    } else {
      unforceSmartPlugDevice(this.opId, this.platformId, this.groupId, this.selectedPlug.dvc_id)
        .then(res => {
          this.loadPlugs();
        })
        .catch(err => {
          this.showToastError('ไม่สามารถยกเลิกคำสั่งได้ กรุณาลองอีกครั้ง', err);
        });
    }

    setTimeout(() => {
      this.closeSelectedPlugCommand();
    }, 1);
  }

  onClickSaveSchedules(schedule: any, plugIndexes: number[]) {
    forEach(plugIndexes, index => {
      this.plugs[index].schedules.common.push(schedule);
    });
  }

  mapGlobalSchedules(subgroups: any[]) {
    let schedules: any[] = [];
    forEach(subgroups, subgroup => {
      if (subgroup.schedules && subgroup.schedules.length) {
        const schedule = subgroup.schedules[0];
        const mappedSchedule = this.getMappedSchedule(schedule, subgroup);
        schedules.push(mappedSchedule);
      }
    });
    this.globalSchedules = sortBy(cloneDeep(schedules), schedule => {
      return schedule.sgp_id;
    });
  }

  onSchedulesUpdated(schedules: any[]) {}

  loadUnbindedPlugs() {
    this.isAddPlugPopupVisible = true;
  }

  loadData() {
    this.isLoadingPlugs = true;

    getSmartPlugPlatforms(this.opId)
      .then(res => {
        this.platforms = map(res.data, platform => {
          return { id: platform.plt_id, title: platform.dflt_name, ...platform };
        });

        let plt = this.selectedPlatform || this.platforms[0];
        return getSmartPlugPlatformGroups(this.opId, plt.plt_id);
      })
      .then(res => {
        this.groups = map(res.data, group => {
          return { id: group.grp_id, ...group };
        });
        return getSmartPlugDevices(this.opId, this.platformId, this.groupId);
      })
      .then(res => {
        this.mapPlugs(res.data);
        this.selectPlugFromQueryIfNeeded();

        return getSmartPlugSubGroups(this.opId, this.platformId, this.groupId);
      })
      .then(res => {
        this.mapGlobalSchedules(res.data);
        this.isLoadingPlugs = false;
        this.updateRefreshDate();

        if (!this.selectedPlatform) {
          this.onSelectedPlatform(this.platforms[0]);
        }
      })
      .catch(err => {
        this.showToastError(null, err);
        this.isLoadingPlugs = false;
      })
      .finally(() => {
        this.isPreventRefreshInterval = false;
      });
  }

  mapPlugs(plugData: [any]) {
    let plugs = map(plugData, plug => {
      return this.getMappedPlug(plug);
    });
    this.plugs = plugs;

    if (this.selectedPlug) {
      let plug = find(this.plugs, { dvc_id: this.selectedPlug.dvc_id });
      this.selectPlug(plug);
    }
  }

  selectPlugFromQueryIfNeeded() {
    const plugId = this.$route.query.plug;
    if (plugId) {
      let plug = find(this.plugs, { id: plugId });
      if (plug) {
        plug.type = 'plug';
        this.selectDevice(plug);
      } else {
        this.$router.replace({ query: {} });
      }
    }
  }

  loadPlugs() {
    this.closeManageMenus();
    this.isLoadingPlugs = true;
    getSmartPlugDevices(this.opId, this.platformId, this.groupId)
      .then(res => {
        this.mapPlugs(res.data);
        this.selectPlugFromQueryIfNeeded();
        this.updateRefreshDate();
      })
      .catch(err => {
        this.showToastError(null, err);
      })
      .finally(() => {
        this.isLoadingPlugs = false;
      });
  }

  loadGlobalSchedules() {
    getSmartPlugSubGroups(this.opId, this.platformId, this.groupId).then(res => {
      this.mapGlobalSchedules(res.data);
    });

    // let serializedSchedules = localStorage.getItem('schedules');
    // if (serializedSchedules) {
    //   this.globalSchedules = JSON.parse(serializedSchedules);
    // }
  }

  testLoadPlugs() {
    let serializedSchedules = localStorage.getItem('plugs');
    if (serializedSchedules) {
      this.plugs = JSON.parse(serializedSchedules);
    } else {
      this.plugs = this.plugList;
      localStorage.setItem('plugs', JSON.stringify(this.plugs));
    }

    this.loadGlobalSchedules();
    this.closeManageMenus();
  }

  showToastSuccess(message: string | null) {
    const msg = message || 'บันทึกเรียบร้อย';
    this.$toasted.show(msg, { type: 'success' });
  }

  showToastError(message: string | null, error: any = null) {
    if (this.isLoadingPlugs) {
      this.isLoadingPlugs = false;
    }
    var msg = message || 'Unexpected error occured. Please try again.';

    if (error) {
      if (error.response.data.stat === 4241601) {
        msg = 'ไม่สามารถเชื่อมต่อปลั๊กได้ กรุณาลองอีกครั้ง';
      } else if (error.response.data.stat === 403) {
        msg = 'Access Denied';
      }
      msg = `${msg} (${error.response.data.stat})`;
    }

    this.$toasted.show(msg, { type: 'error' });
  }

  showAllPlugsCommandToastError(error: any = null) {
    var msg = 'Unexpected error occured. Please try again.';

    if (error) {
      if (error.response.data.stat === 4241601) {
        msg = 'ไม่สามารถเชื่อมต่อปลั๊กบางตัวที่อยู่ในสถานะ Offline ได้ กรุณาลองอีกครั้ง';
      } else if (error.response.data.stat === 403) {
        msg = 'Access Denied';
      }
      msg = `${msg} (${error.response.data.stat})`;
    }
    this.$toasted.show(msg, { type: 'error' });
  }

  updateRefreshDate() {
    this.refreshDate = `${moment().format('HH:mm')} น.`;
  }

  initRefreshInterval() {
    this.refreshInterval = setInterval(() => {
      if (!this.isPreventRefreshInterval && !this.isPopupVisible && !this.isLoadingPlugs) {
        this.loadData();
      }
    }, 1000 * 60);
  }

  mounted() {
    this.loadData();
    this.initRefreshInterval();
  }

  beforeDestroy() {
    clearInterval(this.refreshInterval);
    this.refreshInterval = null;
  }
}
