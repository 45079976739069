
import { Component, Prop, Vue } from 'vue-property-decorator';
import { cloneDeep, filter, map, isEqual } from 'lodash';

import CloseButton from '@/components/CloseButton.vue';
import TextField from '@/components/form/TextField.vue';
import SearchTextField from '@/components/form/SearchTextField.vue';

import SmartPlugSavePopup from '@/components/smart-plug/popup/SmartPlugScheduleSavePopup.vue';

@Component({
  components: {
    CloseButton,
    TextField,
    SearchTextField,
    SmartPlugSavePopup,
  },
})
export default class SmartPlugLinkDevicePopup extends Vue {
  @Prop() readonly plugName!: string | '';
  @Prop() readonly originalSelectedDevices!: any[];
  @Prop() readonly availablePlugList!: any[];
  @Prop() readonly availableAndroidBoxList!: any[];

  @Prop() readonly isProcessing!: boolean;

  isSavePopupVisible: boolean = false;
  hasDiscardButton: boolean = false;

  isError: boolean = false;

  selectedTabItem: any = this.defaultSelectedTabItem;

  selectedDevices: any = [];

  searchText: string = '';

  get defaultSelectedTabItem(): any {
    return {
      id: 0,
      name: 'กล่องสัญญาณ',
      mode: 'android-box',
      icon: 'icon-32-ic-androidbox',
    };
  }

  get tabItemList(): any[] {
    return [
      this.defaultSelectedTabItem,
      {
        id: 1,
        name: 'สมาร์ทปลั๊ก',
        mode: 'smart-plug',
        icon: 'icon-32-ic-plug',
      },
    ];
  }

  get hasDataChanged() {
    return !isEqual(this.originalSelectedDevices, this.selectedDevices);
  }

  get plugDeviceList() {
    return map(this.availablePlugList, plug => {
      return { ...plug, type: 'plug', name: plug.dflt_name, id: plug.id };
    });
  }

  get androidBoxDeviceList() {
    return map(this.availableAndroidBoxList, box => {
      return { ...box, type: 'android_box', name: box.name, id: box.usr_id };
    });
  }

  get selectedDeviceIds() {
    return map(this.selectedDevices, device => {
      return device.id;
    });
  }

  get filteredDeviceList() {
    const deviceList =
      this.selectedTabItem.id === 0 ? this.androidBoxDeviceList : this.plugDeviceList;

    let devices = filter(deviceList, (device: any) => {
      return (
        device.name &&
        device.name.toLowerCase().includes(this.searchText.toLowerCase()) &&
        !this.selectedDeviceIds.includes(device.id)
      );
    });
    return devices;
  }

  isSelectedTab(item: any) {
    if (this.selectedTabItem) {
      return this.selectedTabItem.id === item.id;
    }
  }

  selectTab(item: any) {
    if (this.selectedTabItem.id !== item.id) {
      this.selectedTabItem = item;
    }
  }

  onSearchDeviceTextChanged(text: string) {
    this.searchText = text;
  }

  selectAllDevices(selected: boolean) {
    if (selected) {
      this.selectedDevices = this.selectedDevices.concat(this.filteredDeviceList);
    } else {
      this.selectedDevices = [];
    }
  }

  selectDevice(device: any) {
    this.selectedDevices.unshift(device);
  }

  removeDevice(device: any) {
    this.selectedDevices = filter(this.selectedDevices, dv => {
      return device.id !== dv.id;
    });
  }

  linkDevices() {
    if (this.hasDataChanged) {
      this.hasDiscardButton = false;
      this.isSavePopupVisible = true;
    } else {
      this.dismiss();
    }
  }

  onClickClose() {
    if (this.hasDataChanged) {
      this.hasDiscardButton = true;
      this.isSavePopupVisible = true;
    } else {
      this.dismiss();
    }
  }

  dismiss() {
    this.$emit('onClose');
  }

  onClickSaveChanged() {
    this.$emit('onLinkedDeviceSelected', this.selectedDevices);
  }

  onClickDiscardChanged() {
    this.dismiss();
  }

  mounted() {
    this.selectedDevices = cloneDeep(this.originalSelectedDevices);
  }
}
