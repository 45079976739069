
// @ts-nocheck

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { ToggleButton } from 'vue-js-toggle-button';
import { filter, cloneDeep, findIndex, forEach, map, find, last, sortBy } from 'lodash';

import CloseButton from '@/components/CloseButton.vue';
import SearchTextField from '@/components/form/SearchTextField.vue';
import SmartPlugScheduleTimeline from '@/components/smart-plug/schedule/SmartPlugScheduleTimeline.vue';
import TextField from '@/components/form/TextField.vue';

/////// Begin Global ////////

import SmartPlugAddPlugToSchedulePopup from '@/components/smart-plug/popup/SmartPlugAddPlugToSchedulePopup.vue';
import SmartPlugAddPlugToSchedule from '@/components/smart-plug/schedule/SmartPlugAddPlugToSchedule.vue';
import SmartPlugScheduleDeletePopup from '@/components/smart-plug/popup/SmartPlugScheduleDeletePopup.vue';
import SmartPlugScheduleSavePopup from '@/components/smart-plug/popup/SmartPlugScheduleSavePopup.vue';

import SmartPlugUtils from '@/utils/smartplug';

import {
  createSmartPlugGlobalSchedule,
  updateSmartPlugGlobalSchedule,
  updateSmartPlugGlobalScheduleDevices,
  activateSmartPlugGlobalSchedule,
  deactivateSmartPlugGlobalSchedule,
  deleteSmartPlugGlobalSchedule,
} from '@/services/axios';

@Component({
  components: {
    CloseButton,
    SearchTextField,
    ToggleButton,
    SmartPlugScheduleTimeline,
    TextField,
    SmartPlugAddPlugToSchedulePopup,
    SmartPlugAddPlugToSchedule,
    SmartPlugScheduleDeletePopup,
    SmartPlugScheduleSavePopup,
  },
})
export default class SmartPlugGlobalSchedulePopup extends Vue {
  @Prop() readonly originalPlugList!: any[];
  @Prop() readonly scheduleList!: any[];
  @Prop() readonly platformId!: number;
  @Prop() readonly groupId!: number;
  @Prop({ default: null }) readonly autoSelectedSchedule!: any;

  @State opId!: number;

  @Watch('originalPlugList')
  onOriginalPlugListChanged() {
    this.plugList = this.originalPlugList;
  }

  @Watch('scheduleList', { deep: true })
  onScheduleListChanged() {
    this.currentScheduleList = cloneDeep(this.scheduleList);
    if (this.isCreateFinishing) {
      if (this.currentScheduleList.length) {
        this.selectSchedule(last(this.currentScheduleList), true);
      }
      this.isCreateFinishing = false;
    } else if (this.selectedSchedule) {
      let scheduleTimelineRef: any = this.$refs.scheduleTimeline;
      if (scheduleTimelineRef) {
        // eslint-disable-next-line
        scheduleTimelineRef.currentSchedule.is_act = this.currentActivationStatus;
      }
    }
  }

  // List of Plugs.
  plugList: any[] = [];

  // List of schedules.
  currentScheduleList: any[] = [];

  // Processing state to show/hide loader, enable/disable elements.
  isProcessing: boolean = false;

  // Processing state to show/hide loader, enable/disable elements of ScheduleTimeline component.
  isTimelineProcessing: boolean = false;

  // Error state if required input is missing. (e.g. Schedule Name)
  isError: boolean = false;

  // Error state if schedule timeline input missing. (e.g. Schedule has no at least one timeline)
  isTimelineError: boolean = false;

  // Is on creating schedule state.
  isCreatingSchedule: boolean = false;

  // Processing state to show/hide loader, enable/disable elements of any Popup component.
  isPopupProcessing: boolean = false;

  // Finishing state of schedule creation. Use to auto-select latest added schedule.
  isCreateFinishing: boolean = false;

  // Initialized current schedule.
  currentSchedule: any = this.getNewSchedule();

  searchScheduleText: string = '';

  // Input schedule name.
  scheduleName: string = '';

  // Should save schedule name when try to dismiss current schedule editing.
  shouldSaveScheduleName: boolean = false;
  // Should save schedule timeline when try to dismiss current schedule editing.
  shouldSaveScheduleTimeline: boolean = false;
  // Should save schedule plug list when try to dismiss current schedule editing.
  shouldSaveSchedulePlugList: boolean = false;

  // Original schedule before editing.
  originalSchedule: any = null;

  selectedSchedule: any = null;

  // Keep next selected schedule if confirm changes save popup appear before change schedule.
  pendingSelectedSchedule: any = null;

  // Next action after finish confirm changes save popup. ('create', 'close')
  nextAction: string = '';

  isAddPlugPopupVisible: boolean = false;
  isDeletePopupVisible: boolean = false;
  isSavePopupVisible: boolean = false;

  isEditingScheduleName: boolean = false;
  isEditingSchedule: boolean = false;
  isEditingPlugList: boolean = false;

  isViewingSchedule: boolean = false;
  isViewingPlug: boolean = false;

  // Flag to detemined toggle button state
  isScheduleActive: boolean = false;

  // Flag to detemined toggle button state to set to Schedule Timeline component.
  currentActivationStatus: boolean = false;

  // Is data processing. Binded to loader.
  get isDataProcessing() {
    return this.isTimelineProcessing || this.isProcessing;
  }

  // List of plugs that can be added to schedule.
  get availablePlugList() {
    return this.originalPlugList;
  }

  // Check if any popup visible.
  get isPopupVisible() {
    return this.isAddPlugPopupVisible || this.isDeletePopupVisible || this.isSavePopupVisible;
  }

  // Filtered schedules.
  get filteredSchedules() {
    let schedules = filter(this.currentScheduleList, (schedule: any) => {
      return (
        schedule.name && schedule.name.toLowerCase().includes(this.searchScheduleText.toLowerCase())
      );
    });
    return sortBy(schedules, schedule => {
      return schedule.sgp_id;
    });
  }

  // Return: New schedule object.
  getNewSchedule() {
    return {
      sch_id: [...Array(30)].map(() => Math.random().toString(36)[2]).join(''),
      name: '',
      is_act: false,
      days: [
        {
          times: [],
        },
        {
          times: [],
        },
        {
          times: [],
        },
        {
          times: [],
        },
        {
          times: [],
        },
        {
          times: [],
        },
        {
          times: [],
        },
      ],
      plugs: [],
    };
  }

  // Return: `true` if schedule is selected else `false`
  isSelectedSchedule(schedule: any) {
    if (this.selectedSchedule) {
      return (
        `${this.selectedSchedule.sch_id}_${this.selectedSchedule.sgp_id}` ===
        `${schedule.sch_id}_${schedule.sgp_id}`
      );
    }
    return false;
  }

  /**
   * Show save popup before dismiss current timeline editing
   * Return: `true` if popup need to be displayed else `false`
   */
  showSavePopupIfNeeded() {
    let shouldShowSavePopup: boolean = false;

    this.shouldSaveScheduleName = false;
    this.shouldSaveScheduleTimeline = false;
    this.shouldSaveSchedulePlugList = false;

    if (!this.originalSchedule) {
      return false;
    }

    if (this.originalSchedule.name !== this.scheduleName) {
      shouldShowSavePopup = true;
      this.shouldSaveScheduleName = true;
    }
    if (this.isViewingSchedule || this.isCreatingSchedule) {
      let scheduleTimelineRef: any = this.$refs.scheduleTimeline;
      if (scheduleTimelineRef) {
        scheduleTimelineRef.checkDataChangedStatus();
        if (scheduleTimelineRef.hasDataChanged) {
          shouldShowSavePopup = true;
          this.shouldSaveScheduleTimeline = true;
        }
      }
    } else if (this.isViewingPlug) {
      let addPlugListRef: any = this.$refs.addPlugList;
      if (addPlugListRef) {
        addPlugListRef.checkDataChangedStatus();
        if (addPlugListRef.hasDataChanged) {
          shouldShowSavePopup = true;
          this.shouldSaveSchedulePlugList = true;
        }
      }
    }

    this.isSavePopupVisible = shouldShowSavePopup;

    return shouldShowSavePopup;
  }

  /**
   * Select schedule
   * schedule: Schedule
   * force: `true` if force select and change current schedule to new schedule else `false` if to determine save popup should be displayed first.
   */
  selectSchedule(schedule: any, force: boolean) {
    if (!schedule) {
      this.selectedSchedule = null;
      this.currentSchedule = null;
      return;
    }

    let shouldShowSavePopup =
      force || !this.selectedSchedule ? false : this.showSavePopupIfNeeded();

    if (!shouldShowSavePopup) {
      this.isCreatingSchedule = false;
      this.selectedSchedule = cloneDeep(schedule);
      this.currentSchedule = cloneDeep(this.selectedSchedule);
      this.currentActivationStatus = this.selectedSchedule.is_act;
      this.isViewingSchedule = true;
      this.isViewingPlug = false;
      this.isEditingScheduleName = false;
      this.scheduleName = this.currentSchedule.name;
      this.originalSchedule = cloneDeep(this.currentSchedule);
      this.isSavePopupVisible = false;

      const scheduleIndex = findIndex(this.filteredSchedules, schedule);

      this.$nextTick(() => {
        if (scheduleIndex >= 0) {
          const scheduleItemRefs: any = this.$refs.schedule_item;
          if (scheduleItemRefs) {
            const selectedItem: any = scheduleItemRefs[scheduleIndex];
            if (selectedItem) {
              selectedItem.scrollIntoView({ block: 'center' });
            }
          }
        }
      });

      let scheduleTimelineRef: any = this.$refs.scheduleTimeline;
      if (scheduleTimelineRef) {
        scheduleTimelineRef.isEditingSchedule = false;
      }
    } else {
      this.pendingSelectedSchedule = schedule;
    }
  }

  // Show adding plug to schedule after schedule was just created.
  continueAddPlug() {
    this.onClickManagePlugList();
    this.isAddPlugPopupVisible = false;
    this.isEditingPlugList = true;
  }

  // Dismiss continue adding plug to schedule after schedule was just created.
  dismissContinueAddPlug() {
    this.isCreatingSchedule = false;
    this.isViewingSchedule = true;
    this.isAddPlugPopupVisible = false;
  }

  onSearchScheduleTextChanged(text: string) {
    this.searchScheduleText = text;
  }

  /**
   * Open creating schedule
   * force: `true` if force create and change current schedule to new schedule else `false` if to determine save popup should be displayed first.
   */
  onClickCreateNewSchedule(force: boolean) {
    const shouldShowSavePopup = force ? false : this.showSavePopupIfNeeded();
    if (!shouldShowSavePopup) {
      this.currentSchedule = this.getNewSchedule();
      this.selectedSchedule = this.currentSchedule;

      this.isCreatingSchedule = true;
      this.isViewingPlug = false;
      this.isViewingSchedule = false;
      this.scheduleName = '';
      this.isScheduleActive = false;
      this.isError = false;

      this.$nextTick(() => {
        let ref: any = this.$refs.scheduleName;
        if (ref) {
          ref.focus();
        }
      });

      this.originalSchedule = cloneDeep(this.currentSchedule);
    } else {
      this.nextAction = 'create';
    }
  }

  // Trigger when click cancel on schedule name editing.
  onClickCancelScheduleName() {
    this.isEditingScheduleName = false;
  }

  // Trigger when click cancel on schedule timeline editing.
  onClickCancelSchedule() {
    if (this.isCreatingSchedule) {
      this.isCreatingSchedule = false;
    } else {
      const isOnEditingScheduleName = this.isEditingScheduleName;
      const currentEnteringScheduleName = this.scheduleName;

      this.currentScheduleList = cloneDeep(this.scheduleList);

      if (this.selectedSchedule) {
        this.selectSchedule(
          find(this.currentScheduleList, {
            sch_id: this.selectedSchedule.sch_id,
            sgp_id: this.selectedSchedule.sgp_id,
          }),
          true
        );
      }

      this.isEditingSchedule = false;

      if (isOnEditingScheduleName) {
        this.isEditingScheduleName = true;
        this.scheduleName = currentEnteringScheduleName;
      }
    }
  }

  onScheduleNameChanged(text: string) {
    this.scheduleName = text;
    this.isError = false;
  }

  onScheduleActiveChanged(schedule: any) {
    if (!this.isCreatingSchedule) {
      const is_act = !schedule.is_act;

      let scheduleTimelineRef: any = this.$refs.scheduleTimeline;
      if (scheduleTimelineRef) {
        this.isProcessing = true;
        scheduleTimelineRef.isActivationProcessing = true;
        if (is_act) {
          activateSmartPlugGlobalSchedule(
            this.opId,
            this.platformId,
            this.groupId,
            schedule.sgp_id,
            schedule.sch_id
          )
            .then(() => {
              this.showToastSuccess();
              this.updateScheduleList();
              scheduleTimelineRef.currentSchedule.is_act = true;
              this.currentActivationStatus = true;
            })
            .catch(err => {
              this.showToastError(null, err);
            })
            .finally(() => {
              scheduleTimelineRef.isActivationProcessing = false;
              this.isProcessing = false;
            });
        } else {
          deactivateSmartPlugGlobalSchedule(
            this.opId,
            this.platformId,
            this.groupId,
            schedule.sgp_id,
            schedule.sch_id
          )
            .then(() => {
              this.showToastSuccess();
              this.updateScheduleList();
              scheduleTimelineRef.currentSchedule.is_act = false;
              this.currentActivationStatus = false;
            })
            .catch(err => {
              this.showToastError(null, err);
            })
            .finally(() => {
              scheduleTimelineRef.isActivationProcessing = false;
              this.isProcessing = false;
            });
        }
      }
    } else {
      this.isScheduleActive = schedule.is_act;
    }
  }

  onClickSaveScheduleName() {
    if (!this.scheduleName || this.scheduleName.trim().length == 0) {
      this.isError = true;
      return;
    }

    this.isProcessing = true;

    updateSmartPlugGlobalSchedule(
      this.opId,
      this.platformId,
      this.groupId,
      this.currentSchedule.sgp_id,
      this.currentSchedule.sch_id,
      {
        dflt_name: this.scheduleName,
      }
    )
      .then(() => {
        this.currentSchedule.name = this.scheduleName;
        this.selectedSchedule.name = this.currentSchedule.name;
        this.originalSchedule = cloneDeep(this.currentSchedule);
        this.updateScheduleList();
        this.isEditingScheduleName = false;
        this.showToastSuccess();
      })
      .catch(err => {
        this.showToastError(null, err);
      })
      .finally(() => {
        this.isProcessing = false;
      });
  }

  onClickSaveSchedule(schedule: any) {
    let scheduleTimelineRef: any = this.$refs.scheduleTimeline;
    if (scheduleTimelineRef) {
      const newSchedule = cloneDeep(this.currentSchedule);
      newSchedule.days = cloneDeep(scheduleTimelineRef.currentSchedule.days);
      newSchedule.name = this.scheduleName;

      const originalRequestBody = SmartPlugUtils.getGlobalScheduleRequestBody(newSchedule);

      if (originalRequestBody.prt_state.length === 0) {
        this.isTimelineError = true;
        return;
      }

      const requestBody = {
        sch_times: originalRequestBody.sch_times,
        prt_state: originalRequestBody.prt_state,
      };

      this.isTimelineProcessing = true;

      updateSmartPlugGlobalSchedule(
        this.opId,
        this.platformId,
        this.groupId,
        this.currentSchedule.sgp_id,
        this.currentSchedule.sch_id,
        requestBody
      )
        .then(() => {
          this.currentSchedule.days = schedule.days;
          this.selectedSchedule.days = this.currentSchedule.days;

          this.originalSchedule = cloneDeep(this.currentSchedule);

          this.updateScheduleList();

          if (scheduleTimelineRef) {
            scheduleTimelineRef.isEditingSchedule = false;
          }
          this.showToastSuccess();
        })
        .catch(err => {
          this.showToastError(null, err);
        })
        .finally(() => {
          this.isTimelineProcessing = false;
        });
    }
  }

  onScheduleTimelineChanged() {
    this.isTimelineError = false;
  }

  onClickCreateSchedule() {
    if (!this.scheduleName || this.scheduleName.trim().length == 0) {
      this.isError = true;
    }

    let scheduleTimelineRef: any = this.$refs.scheduleTimeline;
    if (scheduleTimelineRef) {
      const newSchedule = cloneDeep(this.currentSchedule);
      newSchedule.days = cloneDeep(scheduleTimelineRef.currentSchedule.days);
      newSchedule.name = this.scheduleName;
      newSchedule.is_act = this.isScheduleActive;

      const requestBody = SmartPlugUtils.getGlobalScheduleRequestBody(newSchedule);

      if (requestBody.prt_state.length === 0) {
        this.isTimelineError = true;
      }

      if (this.isError || this.isTimelineError) {
        return;
      }

      this.isProcessing = true;

      createSmartPlugGlobalSchedule(this.opId, this.platformId, this.groupId, requestBody)
        .then(() => {
          this.isCreateFinishing = true;

          this.updateScheduleList();

          if (this.isCreatingSchedule) {
            this.isAddPlugPopupVisible = true;
          }

          this.isCreatingSchedule = false;
          this.isEditingSchedule = false;
          this.isViewingSchedule = true;

          this.showToastSuccess('สร้างตารางเรียบร้อย');
        })
        .catch(err => {
          this.showToastError(null, err);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    }
  }

  onClickEditScheduleName() {
    this.isEditingScheduleName = true;
  }

  // Trigger when click `เพิ่ม/ลด` to go to adding plug to schedule view.
  onClickManagePlugList() {
    this.isViewingSchedule = false;
    this.isViewingPlug = true;
    this.isEditingPlugList = true;
  }

  onClickEditPlugList() {
    this.isEditingPlugList = true;
  }

  onClickSavePlugList(plugList: any[]) {
    this.isProcessing = true;

    updateSmartPlugGlobalScheduleDevices(
      this.opId,
      this.platformId,
      this.groupId,
      this.currentSchedule.sgp_id,
      map(plugList, plug => {
        return plug.dvc_id;
      })
    )
      .then(() => {
        this.currentSchedule.plugs = plugList;
        this.selectedSchedule = this.currentSchedule;
        this.updateScheduleList();

        this.isProcessing = false;

        this.isEditingPlugList = false;
        this.isViewingSchedule = true;
        this.isViewingPlug = false;

        this.originalSchedule = cloneDeep(this.currentSchedule);

        this.showToastSuccess();

        this.updatePlugList();
      })
      .catch(err => {
        this.showToastError(null, err);
      })
      .finally(() => {
        this.isProcessing = false;
      });
  }

  onClickCancelPlugList() {
    this.isEditingPlugList = false;
    this.isViewingSchedule = true;
    this.isViewingPlug = false;
  }

  onClickDismissPlugList() {
    this.isViewingSchedule = true;
    this.isViewingPlug = true;
  }

  onClickDeleteSchedule() {
    this.isPopupProcessing = true;

    deleteSmartPlugGlobalSchedule(
      this.opId,
      this.platformId,
      this.groupId,
      this.currentSchedule.sgp_id
    )
      .then(() => {
        this.showToastSuccess('ลบตารางเรียบร้อย');

        this.selectSchedule(null, true);
        this.isEditingScheduleName = false;
        this.isViewingSchedule = false;
        this.isDeletePopupVisible = false;

        this.updateScheduleList();

        this.updatePlugList();
      })
      .catch(err => {
        this.showToastError(null, err);
      })
      .finally(() => {
        this.isPopupProcessing = false;
      });
  }

  // Trigger when click save on changes save popup.
  onClickSaveChanged() {
    let hasDataChanged: boolean = false;
    if (
      this.shouldSaveScheduleName ||
      this.shouldSaveScheduleTimeline ||
      this.shouldSaveSchedulePlugList
    ) {
      this.isPopupProcessing = true;
      hasDataChanged = true;
    }

    if (this.shouldSaveScheduleName || this.isCreatingSchedule) {
      if (!this.scheduleName || this.scheduleName.trim().length == 0) {
        this.isError = true;
        this.isSavePopupVisible = false;
        this.isProcessing = false;
        return;
      }
      this.currentSchedule.name = this.scheduleName;
      this.selectedSchedule.name = this.currentSchedule.name;
    }

    if (this.shouldSaveScheduleTimeline) {
      let scheduleTimelineRef: any = this.$refs.scheduleTimeline;
      if (scheduleTimelineRef) {
        this.currentSchedule.days = scheduleTimelineRef.currentSchedule.days;
        this.selectedSchedule.days = this.currentSchedule.days;
      }
    } else if (this.shouldSaveSchedulePlugList) {
      let addPlugListRef: any = this.$refs.addPlugList;
      if (addPlugListRef) {
        const plugList = addPlugListRef.selectedPlugList;
        if (!plugList.length) {
          forEach(this.originalPlugList, plug => {
            if (plug.global_schedule_id === this.selectedSchedule.sch_id) {
              plug.global_schedule_id = null;
            }
          });
        } else {
          const plugIds = map(plugList, plug => {
            return plug.id;
          });
          forEach(this.originalPlugList, plug => {
            if (plugIds.includes(plug.id)) {
              plug['global_schedule_id'] = this.selectedSchedule.sch_id;
            }
          });
        }

        this.selectedSchedule.plugs = plugList;
        this.currentSchedule = this.selectedSchedule;
      }
    }

    if (hasDataChanged) {
      let scheduleTimelineRef: any = this.$refs.scheduleTimeline;
      if (scheduleTimelineRef) {
        const newSchedule = cloneDeep(this.currentSchedule);
        newSchedule.days = cloneDeep(scheduleTimelineRef.currentSchedule.days);
        newSchedule.name = this.scheduleName;

        const originalRequestBody = SmartPlugUtils.getGlobalScheduleRequestBody(newSchedule);

        if (originalRequestBody.prt_state.length === 0) {
          this.isTimelineError = true;
          return;
        }

        const requestBody = {
          sch_times: originalRequestBody.sch_times,
          prt_state: originalRequestBody.prt_state,
          dflt_name: originalRequestBody.dflt_name,
        };

        this.isPopupProcessing = true;

        updateSmartPlugGlobalSchedule(
          this.opId,
          this.platformId,
          this.groupId,
          this.currentSchedule.sgp_id,
          this.currentSchedule.sch_id,
          requestBody
        )
          .then(() => {
            this.originalSchedule = cloneDeep(this.currentSchedule);
            this.isPopupProcessing = false;

            if (this.isCreatingSchedule) {
              this.currentScheduleList.push(this.currentSchedule);
            }

            this.updateScheduleList();

            if (this.pendingSelectedSchedule) {
              this.selectSchedule(this.pendingSelectedSchedule, true);
            }
            this.processNextAction();

            this.isSavePopupVisible = false;

            this.showToastSuccess();
          })
          .catch(err => {
            this.showToastError(null, err);
          })
          .finally(() => {
            this.isPopupProcessing = false;
          });
      }
    }
  }

  onClickDiscardChanged() {
    if (this.pendingSelectedSchedule) {
      this.selectSchedule(this.pendingSelectedSchedule, true);
      return;
    }
    this.processNextAction();
  }

  processNextAction() {
    if (this.nextAction === 'create') {
      this.onClickCreateNewSchedule(true);
      this.isSavePopupVisible = false;
    } else if (this.nextAction === 'close') {
      this.close();
    }
    this.nextAction = '';
  }

  onClickClose() {
    const shouldShowSavePopup = this.showSavePopupIfNeeded();
    if (!shouldShowSavePopup) {
      this.close();
    } else {
      this.nextAction = 'close';
    }
  }

  showToastSuccess(message: string | null = null) {
    const msg = message || 'บันทึกเรียบร้อย';
    this.$toasted.show(msg, { type: 'success' });
  }

  showToastError(message: string | null, error: any = null) {
    let msg = message || 'Unexpected error occured. Please try again.';

    if (error) {
      if (error.response.data.stat === 4241601) {
        msg = 'ไม่สามารถเชื่อมต่อปลั๊กได้ กรุณาลองอีกครั้ง';
      } else if (error.response.data.stat === 403) {
        msg = 'Access Denied';
      }
      msg = `${msg} (${error.response.data.stat})`;
    }

    this.$toasted.show(msg, { type: 'error' });
  }

  updateScheduleList() {
    this.$emit('onScheduleListUpdated');
  }

  updatePlugList() {
    this.$emit('onPlugListUpdated');
  }

  mounted() {
    this.currentScheduleList = cloneDeep(this.scheduleList);

    if (this.autoSelectedSchedule) {
      this.selectSchedule(this.autoSelectedSchedule, true);
    }
  }

  close() {
    this.$emit('onClose');
  }
}
