
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ToggleButton } from 'vue-js-toggle-button';

import { isEqual, cloneDeep, times, map } from 'lodash';
import moment from 'moment-timezone';

// @ts-ignore
import VueDraggableResizable from 'vue-draggable-resizable';

import TextField from '@/components/form/TextField.vue';

import SmartPlugScheduleSavePopup from '@/components/smart-plug/popup/SmartPlugScheduleSavePopup.vue';

const minTimelineMinutes = 5;

@Component({
  components: {
    TextField,
    ToggleButton,
    VueDraggableResizable,
    SmartPlugScheduleSavePopup,
  },
})
export default class SmartPlugScheduleTimeline extends Vue {
  @Prop({ default: false }) readonly isNewSchedule!: boolean;
  @Prop() schedule!: any;
  @Prop() readonly isError!: boolean;
  @Prop({ default: false }) readonly isProcessing!: boolean;
  @Prop({ default: false }) readonly isReadonly!: boolean;
  @Prop({ default: 32 }) readonly margin!: number;
  @Prop() readonly plug!: any;
  @Prop({ default: false }) readonly isViewMode!: boolean;

  // Type: common | special
  @Prop({ default: 'common' })
  readonly type!: string;

  @Watch('currentTimelineWidthMultiplierIndex')
  onCurrentTimelineWidthMultiplierIndexChanged() {
    this.reCalculateTimeline();
  }

  @Watch('schedule', { deep: true })
  onScheduleChanged() {
    if (!this.isScheduleEditable || this.isNewSchedule) {
      this.originalSchedule = cloneDeep(this.schedule);
      this.currentSchedule = cloneDeep(this.schedule);
      this.reCalculateTimeline();
      this.onClickTimeline(-1);
    }
  }

  @Watch('currentSchedule', { deep: true })
  onCurrentScheduleChanged() {
    this.$emit('onScheduleTimelineChanged');
  }

  isEditingSchedule: boolean = false;
  originalSchedule: any = null;
  currentSchedule: any = null;
  isDeletePopupVisible: boolean = false;
  isSavePopupVisible: boolean = false;
  canDiscardOnConfirmSave: boolean = true;
  currentTimelineIndex: number = -1;
  hasDataChanged: boolean = false;
  showTimeline: boolean = true;
  isActivationProcessing: boolean = false;

  currentTimelineWidthMultiplierIndex: number = 2;

  isMissingScheduleName: boolean = false;
  shouldSwitchToListMode: boolean = false;

  get isScheduleEditable() {
    return !this.isReadonly || this.isEditingSchedule || this.isNewSchedule;
  }

  get timelineBlocks() {
    return Array.from(Array(288).keys());
  }

  get currentTimelineWidthMultiplier() {
    return this.timelineWidthMultipliers[this.currentTimelineWidthMultiplierIndex];
  }

  get timelineWidthMultipliers() {
    return [12, 6, 2];
  }

  get isPopupVisible() {
    return this.isSavePopupVisible;
  }

  get days() {
    return ['จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์', 'อาทิตย์'];
  }

  get times() {
    let threeHoursLengths: any = [];
    Array(9)
      .fill(0)
      .forEach((acc, index) => {
        threeHoursLengths.push(moment({ hour: index * 3 }).format('HH:mm'));
      });

    let oneHourLengths: any = [];
    Array(25)
      .fill(0)
      .forEach((acc, index) => {
        oneHourLengths.push(moment({ hour: index }).format('HH:mm'));
      });

    let halfHourLengths: any = [];
    Array(24)
      .fill(0)
      .forEach((acc, index) => {
        halfHourLengths.push(moment({ hour: index, minute: 0 }).format('HH:mm'));
        halfHourLengths.push(moment({ hour: index, minute: 30 }).format('HH:mm'));
      });
    halfHourLengths.push(moment({ hour: 24, minute: 0 }).format('HH:mm'));

    return [halfHourLengths, oneHourLengths, threeHoursLengths][
      this.currentTimelineWidthMultiplierIndex
    ];
  }

  onScheduleNameChanged(text: string) {
    this.currentSchedule.name = text;
    this.isMissingScheduleName = false;
  }

  onClickTimeline(index: number) {
    this.currentTimelineIndex = index;
  }

  onDragTimeline(x: any, y: any) {
    const time = this.currentSchedule.days[this.currentTimelineIndex].times[0];
    if (time) {
      const width = time.width;
      this.updateTimeline(x, width);
    }
  }

  onResizeTimeline(handle: any, x: any, y: any, width: any, height: any) {
    this.updateTimeline(x, width);
  }

  updateTimeline(x: any, width: any) {
    const startMins = (x / this.currentTimelineWidthMultiplier) * minTimelineMinutes;
    const start =
      `${Math.floor(startMins / 60)}`.padStart(2, '0') + ':' + `${startMins % 60}`.padStart(2, '0');
    const endMins = ((x + width) / this.currentTimelineWidthMultiplier) * minTimelineMinutes;
    const end =
      `${Math.floor(endMins / 60)}`.padStart(2, '0') + ':' + `${endMins % 60}`.padStart(2, '0');

    this.currentSchedule.days[this.currentTimelineIndex].times = [
      {
        start: start,
        end: end,
        startMins: startMins,
        endMins: endMins,
        x: this.getTimelineXFromStart(start),
        width: this.getTimelineWidthFromStart(start, end),
      },
    ];
  }

  reCalculateTimeline() {
    this.showTimeline = false;

    times(7, t => {
      let day = this.currentSchedule.days[t];
      if (day) {
        const times = day.times;
        if (times && times.length) {
          const time = times[0];
          this.currentSchedule.days[t].times[0].x = this.getTimelineXFromStart(time.start);
          this.currentSchedule.days[t].times[0].width = this.getTimelineWidthFromStart(
            time.start,
            time.end
          );
        }
      }
    });
    setTimeout(() => {
      this.showTimeline = true;
    }, 0);
  }

  removeTimeline(dayIndex: number) {
    this.currentSchedule.days[dayIndex].times.shift();
  }

  onClickTimelineBlock(dayIndex: number, blockIndex: number) {
    if (this.currentSchedule.days[dayIndex].times.length > 0 || !this.isScheduleEditable) {
      return;
    } else {
      const startMins = blockIndex * minTimelineMinutes;
      let endMins = startMins + 180;

      if (endMins > 1440) {
        endMins = 1440;
      }

      const start =
        `${Math.floor(startMins / 60)}`.padStart(2, '0') +
        ':' +
        `${startMins % 60}`.padStart(2, '0');
      const end =
        `${Math.floor(endMins / 60)}`.padStart(2, '0') + ':' + `${endMins % 60}`.padStart(2, '0');

      this.currentSchedule.days[dayIndex].times.push({
        end: end,
        endMins: endMins,
        start: start,
        startMins: startMins,
        x: this.getTimelineXFromStart(start),
        width: this.getTimelineWidthFromStart(start, end),
      });

      this.onClickTimeline(dayIndex);
    }
  }

  getTimelineDisplayedTimeMarginLeft(time: any) {
    const left = time.x;
    const width = time.width;
    const maxWidth = 288 * this.currentTimelineWidthMultiplier;
    const contentMaxX = left + width + 10;

    let marginLeft = 0;
    if (contentMaxX + 90 > maxWidth) {
      marginLeft = left - 90 - 10;
    } else {
      marginLeft = contentMaxX + 10;
    }

    return `${marginLeft}px`;
  }

  getTimelineXFromStart(start: any) {
    const mins = moment.duration(start).asMinutes();
    return (mins / 5) * this.currentTimelineWidthMultiplier;
  }

  getTimelineWidthFromStart(start: any, end: any) {
    const startMins = moment.duration(start).asMinutes();
    const endMins = moment.duration(end).asMinutes();
    const diffMins = endMins - startMins;
    return (diffMins / 5) * this.currentTimelineWidthMultiplier;
  }

  checkDataChangedStatus() {
    let currentScheduleTimes = map(
      map(this.currentSchedule.days, day => {
        return day.times[0];
      }),
      time => {
        if (time) {
          return { start: time.start, end: time.end };
        } else {
          return {};
        }
      }
    );

    let originalScheduleTimes = map(
      map(this.originalSchedule.days, day => {
        return day.times[0];
      }),
      time => {
        if (time) {
          return { start: time.start, end: time.end };
        } else {
          return {};
        }
      }
    );

    this.hasDataChanged = !isEqual(currentScheduleTimes, originalScheduleTimes);
  }

  onClickDelete() {
    this.$emit('onClickDelete');
  }

  onClickCancel() {
    this.checkDataChangedStatus();

    if (this.hasDataChanged) {
      this.shouldSwitchToListMode = true;
      this.canDiscardOnConfirmSave = true;
      this.isSavePopupVisible = true;
    } else {
      this.$emit('onClickCancel', true);
    }
  }

  onClickEditSchedule() {
    this.isEditingSchedule = true;
  }

  onClickCancelEditSchedule() {
    this.$emit('onClickCancel');
    this.isEditingSchedule = false;
  }

  onClickSaveSchedule() {
    this.$emit('onClickSaveSchedule', this.currentSchedule);
  }

  onClickConfirmSaveDiscard() {
    this.$emit('onClickCancel', this.shouldSwitchToListMode);
  }

  onClickConfirmSave() {
    this.isSavePopupVisible = false;

    this.$emit('onClickSaveSchedule', this.currentSchedule);
  }

  toggleScheduleActive() {
    if (this.isNewSchedule) {
      this.currentSchedule.is_act = !this.currentSchedule.is_act;
    }
    this.$emit('onScheduleActiveChanged', this.currentSchedule);
  }

  mounted() {
    this.originalSchedule = cloneDeep(this.schedule);
    this.currentSchedule = cloneDeep(this.schedule);
    this.reCalculateTimeline();
  }
}
