
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class SmartPlugAddPlugToSchedulePopup extends Vue {
  onClickCancel() {
    this.$emit('onClickCancel');
  }

  onClickConfirm() {
    this.$emit('onClickConfirm');
  }
}
