
import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { filter, sortBy } from 'lodash';
import { ToggleButton } from 'vue-js-toggle-button';

import CloseButton from '@/components/CloseButton.vue';
import TextField from '@/components/form/TextField.vue';
import SearchTextField from '@/components/form/SearchTextField.vue';

import {
  activateSmartPlugLineBotOTP,
  getSmartPlugLineBotChatRooms,
  activateSmartPlugLineBotChatRoom,
  deactivateSmartPlugLineBotChatRoom,
} from '@/services/axios';

@Component({
  components: {
    CloseButton,
    TextField,
    SearchTextField,
    ToggleButton,
  },
})
export default class SmartPlugLineBotConfigPopup extends Vue {
  @State opId!: number;

  @Prop() readonly platformId!: number;
  @Prop() readonly groupId!: number;

  isProcessing: boolean = false;
  isError: boolean = false;
  otp: string = '';
  isOTPSectionActive: boolean = true;
  isChatRoomListSectionActive: boolean = false;
  isLoadingChatRooms: boolean = false;
  searchChatRoomText: string = '';
  chatRoomFetchingErrorMessage: string | null = null;
  chatRooms: any = [];

  get filteredChatRooms() {
    if (this.chatRoomFetchingErrorMessage != null) {
      return [];
    }

    let chatRooms = this.chatRooms;
    chatRooms = filter(chatRooms, (cr: any) => {
      return (
        cr.dflt_name && cr.dflt_name.toLowerCase().includes(this.searchChatRoomText.toLowerCase())
      );
    });
    return chatRooms;
  }

  onTextChanged(otp: string) {
    this.otp = otp;
    this.isError = false;
  }

  onSearchChatRoomTextChanged(text: string) {
    this.searchChatRoomText = text;
  }

  activateOTP() {
    this.isProcessing = true;
    activateSmartPlugLineBotOTP(this.opId, this.platformId, this.groupId, this.otp)
      .then(() => {
        this.showToastSuccess(null);
        this.close();
      })
      .catch(err => {
        if (err.response.data.stat !== 403) {
          this.isError = true;
        } else {
          this.showToastError(null, err);
        }
      })
      .finally(() => {
        this.isProcessing = false;
      });
  }

  showToastSuccess(message: string | null) {
    const msg = message || 'เปิดใช้งานสำเร็จ สามารถตรวจสอบได้ที่กรุ๊ปไลน์';
    this.$toasted.show(msg, { type: 'success' });
  }

  showToastError(message: string | null, error: any = null) {
    var msg = message || 'Unexpected error occured. Please try again.';

    if (error) {
      if (error.response.data.stat === 403) {
        msg = 'Access Denied';
      }
      msg = `${msg} (${error.response.data.stat})`;
    }
    this.$toasted.show(msg, { type: 'error' });
  }

  toggleOTPSection() {
    if (this.isProcessing) {
      return;
    }
    this.isChatRoomListSectionActive = false;
    this.isOTPSectionActive = !this.isOTPSectionActive;
  }

  toggleChatRoomListSection() {
    if (this.isProcessing) {
      return;
    }
    this.isOTPSectionActive = false;
    this.isChatRoomListSectionActive = !this.isChatRoomListSectionActive;

    if (this.isChatRoomListSectionActive && this.chatRoomFetchingErrorMessage != null) {
      this.loadChatRooms();
    }
  }

  toggleChatRoomActive(chatRoom: any) {
    chatRoom.is_processing = true;
    this.isProcessing = true;
    if (chatRoom.is_act) {
      deactivateSmartPlugLineBotChatRoom(
        this.opId,
        this.platformId,
        this.groupId,
        chatRoom.chtrm_id
      )
        .then(res => {
          if (res.data.stat === 2001) {
            this.showToastError('ไม่พบ Bot ในห้องแชทนี้', null);
          }
          this.loadChatRooms();
        })
        .catch(err => {
          this.showToastError(null, err);
          chatRoom.is_processing = false;
        })
        .finally(() => {
          this.isProcessing = false;
        });
    } else {
      activateSmartPlugLineBotChatRoom(this.opId, this.platformId, this.groupId, chatRoom.chtrm_id)
        .then(res => {
          if (res.data.stat === 2001) {
            this.showToastError('ไม่พบ Bot ในห้องแชทนี้', null);
          }
          this.loadChatRooms();
        })
        .catch(err => {
          this.showToastError(null, err);
          chatRoom.is_processing = false;
        })
        .finally(() => {
          this.isProcessing = false;
        });
    }
  }

  loadChatRooms() {
    if (!this.isLoadingChatRooms) {
      this.isLoadingChatRooms = true;

      getSmartPlugLineBotChatRooms(this.opId, this.platformId, this.groupId)
        .then(res => {
          let chatRooms = [];
          const notifies = res.data;
          if (notifies.length > 0) {
            const notify = notifies[0];
            if (notify.chatrooms && notify.chatrooms.length > 0) {
              chatRooms = notify.chatrooms;
            }
          }
          this.chatRooms = sortBy(chatRooms, 'chtrm_id');
          this.chatRoomFetchingErrorMessage = null;
        })
        .catch(err => {
          this.chatRoomFetchingErrorMessage = `${err.response.data.info} (${err.response.data.stat})`;
        })
        .finally(() => {
          this.isLoadingChatRooms = false;
        });
    }
  }

  close() {
    this.$emit('onClose');
  }

  mounted() {
    this.loadChatRooms();
  }
}
