
import { Component, Vue, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { filter, cloneDeep, map, find } from 'lodash';
import CloseButton from '@/components/CloseButton.vue';
import SearchTextField from '@/components/form/SearchTextField.vue';
import { getSmartPlugUnbindedDevices, addSmartPlugDevices } from '@/services/axios';

@Component({
  components: {
    CloseButton,
    SearchTextField,
  },
})
export default class SmartPlugAddPlugPopup extends Vue {
  @Prop({ default: [] }) readonly groups!: any[];
  @Prop({ default: null }) readonly selectedPlatform!: any;

  @State opId!: number;

  isProcessing: boolean = false;
  isLoadingPlugs: boolean = false;
  isError: boolean = false;

  plugs: any[] = [];

  selectedPlugs: any[] = [];
  searchText: string = '';

  get filteredPlugs() {
    let plugs = filter(this.plugs, (plug: any) => {
      return plug.name && plug.name.toLowerCase().includes(this.searchText.toLowerCase());
    });

    return plugs;
  }

  onSearchDeviceTextChanged(text: string) {
    this.searchText = text;
  }

  toggleMultipleDeviceSelected(device: any) {
    if (this.isSelectedMultipleDevice(device)) {
      this.selectedPlugs = filter(this.selectedPlugs, plug => {
        return plug.plt_uuid !== device.plt_uuid;
      });
    } else {
      this.selectedPlugs.push(device);
    }
  }

  selectAllDevices(selected: boolean) {
    if (selected) {
      this.selectedPlugs = cloneDeep(this.filteredPlugs);
    } else {
      const filteredPlugIds = map(this.filteredPlugs, plug => plug.plt_uuid);
      this.selectedPlugs = filter(this.selectedPlugs, plug => {
        return !filteredPlugIds.includes(plug.plt_uuid);
      });
    }
  }

  isSelectedMultipleDevice(device: any) {
    const plug = find(this.selectedPlugs, { plt_uuid: device.plt_uuid });
    if (plug) {
      return true;
    }
    return false;
  }

  loadPlugs() {
    const groupId = this.groups[0].grp_id;
    const platformId = this.selectedPlatform.plt_id;

    this.isLoadingPlugs = true;

    getSmartPlugUnbindedDevices(this.opId, platformId, groupId)
      .then(res => {
        this.plugs = map(res.data, plug => {
          return { ...plug, id: plug.plt_uuid, name: plug.dflt_name };
        });
      })
      .catch(err => {
        this.showToastError(null, err);
      })
      .finally(() => {
        this.isLoadingPlugs = false;
      });
  }

  save() {
    if (this.selectedPlugs.length) {
      let plt_uuids: string[] = map(this.selectedPlugs, plug => plug.plt_uuid);

      const platformId = this.selectedPlatform.plt_id;
      const groupId = this.groups[0].grp_id;

      this.isProcessing = true;

      addSmartPlugDevices(this.opId, platformId, groupId, plt_uuids)
        .then(res => {
          this.showToastSuccess(null);
          this.$emit('onDevicesSaved');
          this.close();
        })
        .catch(err => {
          this.showToastError(null, err);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    }
  }

  showToastSuccess(message: string | null) {
    const msg = message || 'บันทึกเรียบร้อย';
    this.$toasted.show(msg, { type: 'success' });
  }

  showToastError(message: string | null, error: any = null) {
    var msg = message || 'Unexpected error occured. Please try again.';

    if (error) {
      if (error.response.data.stat === 4241601) {
        msg = 'ไม่สามารถเชื่อมต่อปลั๊กได้ กรุณาลองอีกครั้ง';
      } else if (error.response.data.stat === 403) {
        msg = 'Access Denied';
      }
      msg = `${msg} (${error.response.data.stat})`;
    }
    this.$toasted.show(msg, { type: 'error' });
  }

  close() {
    this.$emit('onClose');
  }

  mounted() {
    this.loadPlugs();
  }
}
