
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class SmartPlugScheduleDeletePopup extends Vue {
  @Prop() readonly scheduleName!: string;
  @Prop() readonly isProcessing!: boolean;

  onClickCancel() {
    this.$emit('onClickCancel');
  }

  onConfirmDelete() {
    this.$emit('onConfirmDelete');
  }
}
