
import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { find } from 'lodash';
import CloseButton from '@/components/CloseButton.vue';
import TextField from '@/components/form/TextField.vue';
import VBDropdownSearch from '@/components/custom-views/vb-dropdown-search/classes/vb-dropdown-search.vue';
import { getSmartPlugLineBotSettings, updateSmartPlugLineBotSettings } from '@/services/axios';

@Component({
  components: {
    CloseButton,
    TextField,
    VBDropdownSearch,
  },
})
export default class SmartPlugSettingsPopup extends Vue {
  @State opId!: number;

  @Prop() readonly platformId!: number;
  @Prop() readonly groupId!: number;

  isLoadingSettings: boolean = true;
  isProcessing: boolean = false;
  isError: boolean = false;

  selectedNotifyTime: any = null;
  selectedOfflineTime: any = null;

  plugName: string = '';

  get notifyTimes() {
    return [
      {
        id: 0,
        title: 'ทุก 15 นาที',
        value: 900,
      },
      {
        id: 1,
        title: 'ทุก 30 นาที',
        value: 1800,
      },
      {
        id: 2,
        title: 'ทุก 45 นาที',
        value: 2700,
      },
      {
        id: 3,
        title: 'ทุก 1 ชั่วโมง',
        value: 3600,
      },
      {
        id: 4,
        title: 'ทุก 1 นาที',
        value: 60,
      },
    ];
  }

  get offlineTimes() {
    return [
      {
        id: 0,
        title: '5 นาที',
        value: 300,
      },
      {
        id: 1,
        title: '10 นาที',
        value: 600,
      },
      {
        id: 2,
        title: '15 นาที',
        value: 900,
      },
      {
        id: 3,
        title: '20 นาที',
        value: 1200,
      },
      {
        id: 4,
        title: '25 นาที',
        value: 1500,
      },
      {
        id: 5,
        title: '30 นาที',
        value: 1800,
      },
    ];
  }

  onSelectedNotifyTime(time: any) {
    this.selectedNotifyTime = time;
  }

  onSelectedOfflineTime(time: any) {
    this.selectedOfflineTime = time;
  }

  onTextChanged(name: string) {
    this.plugName = name;
    this.isError = false;
  }

  updateSettings() {
    this.isProcessing = true;
    const settings = {
      ofl_grc_prd: this.selectedOfflineTime.value,
      msg_interval: [this.selectedNotifyTime.value],
    };
    updateSmartPlugLineBotSettings(this.opId, this.platformId, this.groupId, settings)
      .then(() => {
        this.showToastSuccess(null);
        this.close();
      })
      .catch(err => {
        this.showToastError(null, err);
      })
      .finally(() => {
        this.isProcessing = false;
      });
  }

  showToastSuccess(message: string | null) {
    const msg = message || 'บันทึกเรียบร้อย';
    this.$toasted.show(msg, { type: 'success' });
  }

  showToastError(message: string | null, error: any = null) {
    var msg = message || 'Unexpected error occured. Please try again.';

    if (error) {
      if (error.response.data.stat === 403) {
        msg = 'Access Denied';
      }
      msg = `${msg} (${error.response.data.stat})`;
    }
    this.$toasted.show(msg, { type: 'error' });
  }

  close() {
    this.$emit('onClose');
  }

  mounted() {
    // this.isLoadingSettings = false;
    // const offlineTime = find(this.offlineTimes, { value: 1200 });
    // const notifyTime = find(this.notifyTimes, { value: 1800 });

    // this.selectedNotifyTime = notifyTime;
    // this.selectedOfflineTime = offlineTime;

    getSmartPlugLineBotSettings(this.opId, this.platformId, this.groupId)
      .then(res => {
        const settingsList: any[] = res.data;
        if (settingsList.length) {
          const settings = settingsList[0];
          const offlineTime = find(this.offlineTimes, { value: settings.ofl_grc_prd });
          const notifyTime = find(this.notifyTimes, { value: settings.msg_interval[0] });

          this.selectedNotifyTime = notifyTime;
          this.selectedOfflineTime = offlineTime;
        }
      })
      .catch(err => {
        this.showToastError(null, err);
        this.close();
      })
      .finally(() => {
        this.isLoadingSettings = false;
      });
  }
}
