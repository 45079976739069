
// @ts-nocheck
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import CloseButton from '@/components/CloseButton.vue';
import SmartPlugScheduleTimeline from '@/components/smart-plug/schedule/SmartPlugScheduleTimeline.vue';

@Component({
  components: { CloseButton, SmartPlugScheduleTimeline },
})
export default class SmartPlugScheduleViewPopup extends Vue {
  @Prop() readonly schedule!: any;

  close() {
    this.$emit('onClose');
  }

  openSchedule() {
    this.$emit('onOpenSchedule');
  }
}
