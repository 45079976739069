
import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

import CloseButton from '@/components/CloseButton.vue';
import TextField from '@/components/form/TextField.vue';

import { activateSmartPlugLineBotOTP } from '@/services/axios';

@Component({
  components: {
    CloseButton,
    TextField,
  },
})
export default class SmartPlugLineBotOTPPopup extends Vue {
  @State opId!: number;

  @Prop() readonly platformId!: number;
  @Prop() readonly groupId!: number;

  isProcessing: boolean = false;
  isError: boolean = false;
  otp: string = '';

  onTextChanged(otp: string) {
    this.otp = otp;
    this.isError = false;
  }

  updateSettings() {
    this.isProcessing = true;
    activateSmartPlugLineBotOTP(this.opId, this.platformId, this.groupId, this.otp)
      .then(() => {
        this.showToastSuccess(null);
        this.close();

        // bindService(this.opId, this.platformId, this.groupId).then(() => {
        //   this.showToastSuccess(null);
        //   this.close();
        // });
      })
      .catch(err => {
        if (err.response.data.stat !== 403) {
          this.isError = true;
        } else {
          this.showToastError(null, err);
        }
      })
      .finally(() => {
        this.isProcessing = false;
      });
  }

  showToastSuccess(message: string | null) {
    const msg = message || 'เปิดใช้งานสำเร็จ สามารถตรวจสอบได้ที่กรุ๊ปไลน์';
    this.$toasted.show(msg, { type: 'success' });
  }

  showToastError(message: string | null, error: any = null) {
    var msg = message || 'Unexpected error occured. Please try again.';

    if (error) {
      if (error.response.data.stat === 403) {
        msg = 'Access Denied';
      }
      msg = `${msg} (${error.response.data.stat})`;
    }
    this.$toasted.show(msg, { type: 'error' });
  }

  close() {
    this.$emit('onClose');
  }
}
