
import { Component, Prop, Vue } from 'vue-property-decorator';
import CloseButton from '@/components/CloseButton.vue';
import TextField from '@/components/form/TextField.vue';

@Component({
  components: {
    CloseButton,
    TextField,
  },
})
export default class SmartPlugEditNamePopup extends Vue {
  @Prop() readonly prefillName!: string | '';
  @Prop() readonly isProcessing!: boolean;

  isError: boolean = false;

  plugName: string = '';

  onTextChanged(name: string) {
    this.plugName = name;
    this.isError = false;
  }

  updatePlug() {
    if (!this.plugName) {
      this.isError = true;
      return;
    }

    this.$emit('onNameChanged', this.plugName);
  }

  close() {
    this.$emit('onClose');
  }

  mounted() {
    this.plugName = this.prefillName;
  }
}
