
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class DeletePopup extends Vue {
  @Prop() readonly isProcessing!: boolean;
  @Prop() readonly htmlTitle!: string;

  @Prop() readonly cancelButtonTitle!: string;
  @Prop() readonly confirmButtonTitle!: string;

  @Prop({ default: true }) readonly isDanger!: boolean;

  onClickCancel() {
    this.$emit('onClickCancel');
  }

  onClickConfirm() {
    this.$emit('onClickConfirm');
  }
}
