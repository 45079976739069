
import { Component, Prop, Vue } from 'vue-property-decorator';
import naturalSort from 'javascript-natural-sort';
import { cloneDeep, filter, map, find, isEqual, forEach } from 'lodash';
import SearchTextField from '@/components/form/SearchTextField.vue';
import ConfirmPopup from '@/components/popup/ConfirmPopup.vue';

@Component({
  components: {
    SearchTextField,
    ConfirmPopup,
  },
})
export default class SmartPlugAddPlugToSchedule extends Vue {
  @Prop({ default: false }) readonly isEditing!: boolean;
  @Prop() allSchedules!: any;
  @Prop() schedule!: any;
  @Prop() availablePlugList!: any[];
  @Prop() readonly isProcessing!: boolean;

  isConfirmPopupVisible: boolean = false;

  originalSelectedPlugList: any[] = [];

  hasDataChanged: boolean = false;

  searchPlugText: string = '';

  selectedPlugList: any[] = [];

  get ownedSelectedPlugs() {
    let plugs: any[] = [];
    forEach(this.selectedPlugList, plug => {
      if (plug.global_schedule_id !== null) {
        if (plug.sgp_id !== this.schedule.sgp_id) {
          plugs.push(plug);
        }
      }
    });
    return plugs;
  }

  get confirmPopupTitle() {
    let prefix = '';
    if (this.ownedSelectedPlugs.length === 1) {
      prefix = this.ownedSelectedPlugs[0].dflt_name;
    } else if (this.ownedSelectedPlugs.length > 1) {
      prefix = `สมาร์ทปลั๊ก ${this.ownedSelectedPlugs.length} ตัว`;
    }
    return `${prefix} กำลังใช้ตารางอื่นอยู่`;
  }

  get htmlConfirmPopupMessage() {
    let prefix = '';
    if (this.ownedSelectedPlugs.length === 1) {
      prefix = this.ownedSelectedPlugs[0].dflt_name;
    } else if (this.ownedSelectedPlugs.length > 1) {
      prefix = `สมาร์ทปลั๊ก ${this.ownedSelectedPlugs.length} ตัว`;
    }
    return `<div>${prefix} กำลังใช้ตารางเวลาอื่นอยู่\nคุณต้องการย้ายสมาร์ทปลั๊กไปใช้ <span style="color: #99aaff;">"${this.schedule.name}"</span> หรือไม่?</div>`;
  }

  get selectedPlugIds() {
    return map(this.selectedPlugList, plug => {
      return plug.id;
    });
  }

  get filteredPlugList() {
    let plugs = filter(this.availablePlugList, (plug: any) => {
      return (
        plug.dflt_name &&
        plug.dflt_name.toLowerCase().includes(this.searchPlugText.toLowerCase()) &&
        !this.selectedPlugIds.includes(plug.id)
      );
    });

    plugs = plugs.sort((a, b) => naturalSort(a.dflt_name, b.dflt_name));

    return plugs;
  }

  selectPlug(plug: any) {
    this.selectedPlugList.push(plug);
  }

  selectAllPlugs() {
    this.selectedPlugList = this.selectedPlugList.concat(this.filteredPlugList);
  }

  removePlugAtIndex(index: number) {
    this.selectedPlugList.splice(index, 1);
  }

  removeAllSelectedPlugs() {
    this.selectedPlugList = [];
  }

  getCurrentScheduleOfPlug(plug: any) {
    if (plug.global_schedule_id) {
      const schedule = find(this.allSchedules, {
        sch_id: plug.global_schedule_id,
        sgp_id: plug.sgp_id,
      });
      if (schedule) {
        return schedule.name;
      }
    }
    return null;
  }

  checkDataChangedStatus() {
    this.hasDataChanged = !isEqual(this.selectedPlugList, this.originalSelectedPlugList);
  }

  onSearchPlugTextChanged(text: string) {
    this.searchPlugText = text;
  }

  onClickDismiss() {
    this.$emit('onClickDismiss');
  }

  onClickCancel() {
    this.selectedPlugList = cloneDeep(this.schedule.plugs);
    this.$emit('onClickCancel');
  }

  onClickEdit() {
    this.$emit('onClickEdit');
  }

  onClickSave() {
    if (this.ownedSelectedPlugs.length) {
      this.isConfirmPopupVisible = true;
    } else {
      this.$emit('onClickSave', this.selectedPlugList);
    }
  }

  onClickConfirm() {
    this.isConfirmPopupVisible = false;
    this.$emit('onClickSave', this.selectedPlugList);
  }

  mounted() {
    this.selectedPlugList = cloneDeep(this.schedule.plugs);
    this.originalSelectedPlugList = cloneDeep(this.selectedPlugList);
  }
}
