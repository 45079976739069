
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ConfirmPopup extends Vue {
  @Prop() readonly isProcessing!: boolean;
  @Prop() readonly title!: string;
  @Prop() readonly message!: string;
  @Prop() readonly htmlMessage!: string;

  @Prop() readonly cancelButtonTitle!: string;
  @Prop() readonly confirmButtonTitle!: string;
  @Prop() readonly warnTitle!: boolean;

  onClickCancel() {
    this.$emit('onClickCancel');
  }

  onClickConfirm() {
    this.$emit('onClickConfirm');
  }
}
