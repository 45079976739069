// @ts-ignore
import { forEach } from 'lodash';

export default class SmartPlugUtils {
  static getGlobalScheduleRequestBody(schedule: any): any {
    let sch_times: any[] = [];
    let prt_state: any[] = [];

    let index = 1;
    forEach(schedule.days, day => {
      const times = day.times;
      if (times && times.length) {
        const time = times[0];
        sch_times.push([index, time.startMins * 60]);
        prt_state.push(true);

        let endSecs = time.endMins * 60;
        if (endSecs === 86400) {
          endSecs = 86399;
        }
        sch_times.push([index, endSecs]);
        prt_state.push(false);
      }
      index += 1;
      if (index === 7) {
        index = 0;
      }
    });

    const scheduleRequestBody = {
      dflt_name: schedule.name,
      loc_id: 2,
      sch_times,
      prt_state,
      is_act: schedule.is_act,
    };

    return scheduleRequestBody;
  }
}
